import { ErrorBoundary } from '@sentry/react'
import { PostHogProvider } from 'posthog-js/react'
import { type FC, Suspense, lazy, useEffect, useRef } from 'react'
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from 'react-router-dom'

import { Error404, Footer, NavBar } from '@/components'
import { posthog } from '@/utils/posthog'

// Lazy load page components with named exports
const PricesPage = lazy(() =>
	import('./pages/Prices').then((module) => ({ default: module.PricesPage })),
)
const CareersPage = lazy(() =>
	import('./pages/Careers').then((module) => ({ default: module.Careers })),
)
const AboutUsPage = lazy(() =>
	import('./pages/AboutUs').then((module) => ({ default: module.AboutUs })),
)
const HomePage = lazy(() =>
	import('./pages/Home').then((module) => ({ default: module.Home })),
)

const AppContent: FC = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const contactSectionRef = useRef<HTMLElement | null>(null)

	// Handle location state for scrollToContact
	useEffect(() => {
		if (location.pathname === '/' && location.state?.scrollToContact) {
			// Reset the state so it doesn't trigger again on refresh
			window.history.replaceState({}, document.title)

			// Scroll to contact after a short delay to ensure the component is mounted
			setTimeout(() => {
				scrollToContactSection()
			}, 300)
		}
	}, [location])

	// Keep track of the contact section element
	useEffect(() => {
		const updateContactRef = () => {
			if (location.pathname === '/') {
				contactSectionRef.current = document.getElementById('contact')
			}
		}

		updateContactRef()

		const observer = new MutationObserver(updateContactRef)
		observer.observe(document.body, { childList: true, subtree: true })

		return () => observer.disconnect()
	}, [location.pathname])

	const scrollToContact = () => {
		if (location.pathname !== '/')
			navigate('/', { state: { scrollToContact: true } })
		else scrollToContactSection()
	}

	const scrollToContactSection = () => {
		setTimeout(() => {
			const contactSection = document.getElementById('contact')
			if (contactSection) {
				contactSection.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				})
			}
		}, 100)
	}

	return (
		<div className="min-h-screen bg-black text-foreground overflow-x-hidden">
			<div className="fixed inset-0 noise-bg pointer-events-none" />

			<NavBar onBookNowClick={scrollToContact} />

			<Suspense
				fallback={
					<div className="flex h-screen items-center justify-center">
						Loading...
					</div>
				}
			>
				<Routes>
					<Route
						path="/"
						element={<HomePage scrollToContact={scrollToContact} />}
					/>
					<Route path="/prices" element={<PricesPage />} />
					<Route path="/careers" element={<CareersPage />} />
					<Route path="/about-us" element={<AboutUsPage />} />
					<Route path="*" element={<Navigate to="/" replace={true} />} />
				</Routes>
			</Suspense>
			<Footer />
		</div>
	)
}

const App: FC = () =>
	posthog ? (
		<ErrorBoundary fallback={<Error404 />}>
			<PostHogProvider client={posthog}>
				<BrowserRouter>
					<AppContent />
				</BrowserRouter>
			</PostHogProvider>
		</ErrorBoundary>
	) : (
		<ErrorBoundary fallback={<Error404 />}>
			<BrowserRouter>
				<AppContent />
			</BrowserRouter>
		</ErrorBoundary>
	)
export default App
